@import url('https://fonts.googleapis.com/css2?family=Baloo+2:wght@400;600&family=Satisfy&display=swap');
$theme-colors: (
  primary: #dc2424,
  info: #4a569d,
  primaryLight: #ff000085,
  primary2: #4a569d,
  primary2Light: #0327fd59,
  dark: #000,
  whiteTrans: rgba(255, 255, 255, 0.411),
  muted: #d3d3d3
);

$dark-color: #000;

$border-radius: 1.5rem;
$border-radius-lg: 1.5rem;
$border-radius-sm: 1.5rem;

html,
body,
#root {
  height: 100%;
  font-family: 'Baloo 2', cursive;
}

.small {
  font-size: 12px;
}

.smallFont {
  font-size: 12px;
}

.mid {
  font-size: 13px;
}

.large {
  font-size: 14px;
}

.xlarge {
  font-size: 16px;
}

.xxlarge {
  font-size: 18px;
}
.xxxlarge {
  font-size: 24px;
}

.tiny {
  font-size: 10px;
}

.xs {
  font-size: 8px;
}

.xxxxlarge {
  font-size: 28px;
}
.huge {
  font-size: 80px;
}
.xmid {
  font-size: 20px;
}
.fw-thin {
  font-weight: 300;
}
.pointer {
  cursor: pointer;
}

.uploading-image {
  width: 140px;
  height: 140px;
  display: block;
  margin: 0 auto;
  opacity: 0.5;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  object-fit: cover;
}

.left-bar {
  border-left: 10px solid $dark-color;
}

.album-images {
  width: 150px;
  height: 150px;
  border-radius: 4px;
  background-color: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 2px 0 rgba(0, 0, 0, 0.19);
}

.thumbnail-images {
  width: 90%;
  height: 90%;
  border-radius: 4px;
  background-color: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 2px 0 rgba(0, 0, 0, 0.19);
  object-fit: cover;
}

.internal-thumbnail-images {
  width: 150px;
  height: 150px;
  border-radius: 4px;
  background-color: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 2px 0 rgba(0, 0, 0, 0.19);
}

.album-section {
  overflow-x: auto;
}

.steps-delete {
  position: absolute;
  right: 30px;
}

.image-delete {
  position: absolute;
  right: 6px;
  top: 3px;
}

.image-loading-spinner {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  bottom: 50%;
  margin: auto;
}

.disabled-icon {
  color: #d3d3d3 !important;
}

.dragging-box {
  background-color: rgba(244, 238, 238, 0.6) !important;
}

.draggable-box {
  border-bottom: 1px solid lightgray;
  border-radius: 1px !important;
}

.drive-file-uploader {
  border: 2px dotted rgb(210, 205, 205);
  width: 100%;
  height: 100%;
  border-radius: 10px;
}

.search-icon {
  position: absolute;
  right: 0;
  top: 0;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  width: 10%;
  cursor: pointer;
}

.logo {
  font-family: 'Satisfy', cursive;
}

.underline {
  text-decoration: underline;
}

.hover {
  cursor: pointer;
}

.hover-light:hover {
  background-color: #0329fd18;
  cursor: pointer;
}

.hover-dark:hover {
  background-color: #775500;
  cursor: pointer;
}

.a {
  cursor: pointer;
  text-decoration: underline;
}

.vh-100 {
  height: 100vh;
}

.bg-gradient {
  background: map-get($map: $theme-colors, $key: primary); /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    map-get($map: $theme-colors, $key: primary2),
    map-get($map: $theme-colors, $key: primary)
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    map-get($map: $theme-colors, $key: primary2),
    map-get($map: $theme-colors, $key: primary)
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.bg-gradient-light {
  background: map-get($map: $theme-colors, $key: primaryLight); /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    map-get($map: $theme-colors, $key: primary2Light),
    map-get($map: $theme-colors, $key: primaryLight)
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    map-get($map: $theme-colors, $key: primary2Light),
    map-get($map: $theme-colors, $key: primaryLight)
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.rounded-ff-left {
  border-top-left-radius: 14px;
  border-bottom-left-radius: 14px;
}

.rounded-ff-right {
  border-top-right-radius: 14px;
  border-bottom-right-radius: 14px;
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(10px);
  }
  60% {
    transform: translateY(5px);
  }
}

.chevron-animation {
  animation: bounce 2s infinite;
}

@import './components/SlidingSideBar/bar';

@import '~bootstrap/scss/bootstrap';
